import React, { useEffect } from "react";
import * as yup from "yup";
import { useReactHookForm } from "../../hooks/useReactHookForm";
import { getAuth, setAuth } from "../../services/identity.service";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams
} from "react-router-dom";
import MuiSnackbar from "../UI/MuiSnackbar";
import ChangePasswordForm from "./ChangePasswordForm";
import Header from "../UI/Header";
import {
  adminLogin,
  changePasswordWithLogin,
  changePasswordWithoutLogin,
  createPasswordWithoutLogin,
  resetPasswordWithoutLogin
} from "../../services/customers.service";
import { notifyError, notifySuccess } from "../UI/Toaster";

const ChangePassword = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);

  const { token } = useParams();

  const auth = getAuth();
  console.log(token, auth, "token");

  const EventSchema = yup.object().shape({
    newpassword: yup
      .string()
      .min(6, "Password must be of at least 6 characters")
      .required("Password is required!"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("newpassword"), null], "Passwords must match")
      .required("Confirm password is required")
  });

  const { handleSubmit, register, errors, watch } = useReactHookForm({
    validationSchema: EventSchema,
    defaultValues: {},
    mode: "onChange"
  });

  const onSubmitChangePasswordForm = async (data) => {
    console.log(data, "data");
    if (token) {
      let loginData = {
        password: data?.newpassword,
        token
      };
      let ApiRes = location?.pathname?.includes("changepassword")
        ? await createPasswordWithoutLogin(loginData)
        : await resetPasswordWithoutLogin(loginData);
      if (ApiRes?.data?.status) {
        notifySuccess(ApiRes?.data?.message || "Password Created Successfully");
        navigate("/login");
      } else {
        notifyError(ApiRes?.message || "Something went wrong");
      }
    } else {
      let loginData = {
        currentPassword: data?.currentpassword,
        newPassword: data?.newpassword
      };
      let ApiRes = await changePasswordWithLogin(loginData);
      console.log(ApiRes, "ApiRes-");
      if (ApiRes?.data?.status) {
        notifySuccess(ApiRes?.data?.message || "Password Changed Successfully");
        navigate(`/user/${auth?.id}`);
      } else {
        notifyError(ApiRes?.data?.message || "Something went wrong");
      }
    }
    // let udpatedData = { ...data };
    // if (origin) {
    //   udpatedData = { ...udpatedData, origin };
    // }
    // let loginRes = await adminLogin(udpatedData);
    // if (loginRes?.data?.status) {
    //   if (redirectUri) {
    //     const existingUrl = redirectUri;
    //     const url = new URL(existingUrl);
    //     // Append or update additional query parameters
    //     url.searchParams.set("userId", loginRes?.data?.entity?.id);
    //     window.location.href = url;
    //   } else {
    //     new Promise((resolve, _reject) => {
    //       resolve(setAuth(loginRes?.data?.entity));
    //     });
    //     navigate("/customers");
    //   }
    // } else {
    //   setOpen(true);
    //   setMessage(loginRes?.data?.message || "Something went wrong");
    //   setType("error");
    // }
  };

  return (
    <div className={`flex flex-col justify-start ${!auth && "loginPage"}`}>
      {auth && <Header />}
      <div className="flex justify-center items-center h-screen ">
        <div className="bg-white shadow-lg py-8 px-8 w-full border-[1px] md:w-[50%] lg:w-[40%] xl:w-[35%] m-auto rounded-lg">
          {/* <div className="flex justify-center items-center">
          <Image
            src="/images/fv_logo.svg"
            className=" w-[150px] object-cover"
          />
        </div> */}
          <div className="flex flex-col ">
            <label className="text-[30px] font-semibold mt-4">
              {location?.pathname?.includes("changepassword")
                ? "Change Password ?"
                : "Reset Password"}
            </label>
          </div>
          <ChangePasswordForm
            handleSubmit={handleSubmit}
            onSubmit={onSubmitChangePasswordForm}
            register={register}
            errors={errors}
            watch={watch}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
