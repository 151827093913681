import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "./Button";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";
import { notifySuccess } from "./Toaster";
import { logout } from "../../services/customers.service";
import { AiTwotoneMail } from "react-icons/ai";

const Header = ({}) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    let user = localStorage?.getItem("AUTH");
    setUserInfo(JSON?.parse(user));
  }, []);
  //   console.log(JSON?.parse(userInfo), "userInfo");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openmenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogOut = () => {
    let ApiRes = logout();
    localStorage.removeItem("AUTH");

    notifySuccess("Logout Successfully");
    navigate("/login");
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function openEmail() {
    var email = document.getElementById("emailEngagement").innerText;
    window.location.href = "mailto:" + email;
  }
  function openEmail2() {
    var email = document.getElementById("emailSuccess").innerText;
    window.location.href = "mailto:" + email;
  }
  function openCall() {
    var phoneNumber = document.getElementById("phoneNumberNY").innerText;
    window.location.href = "tel:" + phoneNumber;
  }
  function openCall2() {
    var phoneNumber = document.getElementById("phoneNumberNJ").innerText;
    window.location.href = "tel:" + phoneNumber;
  }

  return (
    <div className="bg-white mx-[30px] my-[16px] flex justify-between w-full">
      <div className="flex justify-start items-center">
        <img
          src="/images/flooredathome.png"
          alt="logo"
          className="h-[75px] w-[90px] p-1"
        />
        <div className="hidden sm:flex flex-col items-start text-[12px] ml-2">
          <div className="font-bold">Contact Us</div>
          <div>
            NY :
            <span
              className="underline"
              onClick={() => openCall()}
              id="phoneNumberNY"
            >
              914-449-4190
            </span>{" "}
          </div>
          <div>
            NJ :
            <span
              className="underline"
              onClick={() => openCall2()}
              id="phoneNumberNJ"
            >
              201-561-7366
            </span>{" "}
          </div>
        </div>
      </div>

      <div className="flex justify-between  relative items-center  px-2">
        {/* <Hidden smDown>
          <Button>{userInfo?.email}</Button>
        </Hidden> */}
        <Hidden smDown>
          <div className="m-2 text-[12px]">
            <div className="font-bold text-[12px] flex flex-col items-start">
              Customer Engagement Manager
            </div>
            <div
              className="flex justify-start items-center"
              onClick={() => openEmail()}
            >
              <AiTwotoneMail style={{ fontSize: "14px", marginRight: "2px" }} />
              Kaushal Devani:
              <span className="underline" id="emailEngagement">
                123456789
              </span>
            </div>
          </div>
          <div className=" w-[1px] h-[80%] bg-[gray] " />
          <div className="m-2 text-[12px] flex flex-col items-start">
            <div className="font-bold ">Customer Success Manager</div>
            <div
              className="flex justify-start items-center"
              onClick={() => openEmail2()}
            >
              <AiTwotoneMail style={{ fontSize: "14px", marginRight: "2px" }} />
              Kaushal Devani:
              <span className="underline" id="emailSuccess">
                123456789
              </span>
            </div>
          </div>
        </Hidden>

        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          id="basic-button"
          aria-controls={openmenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openmenu ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openmenu}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem onClick={() => navigate("/")}>Home</MenuItem>
          <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
          <MenuItem onClick={handlelogOut}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
