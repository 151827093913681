import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../components/Login/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import ChangePassword from "../components/Login/ChangePassword";
import HomePage from "../components/Pages/Homepage/HomePage";
import { AuthRequired } from "../middleware/AuthRequired";
import { ToastContainer } from "react-toastify";
// import { AuthRequired } from "../middleware/AuthRequired";
import "react-toastify/dist/ReactToastify.css";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" autoClose={2000} />
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        {/* <Route exact path="/changepassword" element={<ChangePassword />} /> */}
        <Route
          path="/user/:customerId"
          element={
            <AuthRequired>
              <HomePage />
            </AuthRequired>
          }
        />
        <Route
          exact
          path="/changepassword"
          element={
            <AuthRequired>
              <ChangePassword />
            </AuthRequired>
          }
        />
        <Route
          exact
          path="/changepassword/:token"
          element={<ChangePassword />}
        />
        <Route
          exact
          path="/reset-password/:token"
          element={<ChangePassword />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
