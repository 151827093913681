import React, { useEffect } from "react";
import * as yup from "yup";
import { useReactHookForm } from "../../hooks/useReactHookForm";
import { adminLogin } from "../../services/customers.service";
import { getAuth, setAuth } from "../../services/identity.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginForm from "./LoginForm";
import Image from "../UI/Image";
import { notifyError, notifySuccess } from "../UI/Toaster";

const Login = () => {
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const redirectUri = searchParams.get("redirectUri");

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (!!auth?.access_token && !origin && !redirectUri) {
      navigate(`/user/${auth?.id}`);
    }
  }, []);

  const EventSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email is invalid")
      .nullable()
      .required("Email is required!"),
    password: yup
      .string()
      .min(6, "Password must be of at least 6 characters")
      .required("Password is required!")
  });

  const { handleSubmit, register, errors, watch } = useReactHookForm({
    validationSchema: EventSchema,
    defaultValues: {},
    mode: "onChange"
  });

  const onSubmitLoginForm = async (data) => {
    let udpatedData = { ...data };

    // if (origin) {
    //   udpatedData = { ...udpatedData, origin };
    // }
    let loginRes = await adminLogin(udpatedData);
    if (loginRes?.data?.status) {
      new Promise((resolve, _reject) => {
        resolve(setAuth(loginRes?.data?.entity));
      }).then(() => {
        console.log("loginRes", loginRes);
        notifySuccess(loginRes?.data?.message, "Login Successfully");
        navigate(`/user/${loginRes?.data?.entity?.id}`);
      });
      console.log("loginRes", loginRes?.data?.entity?.id);
    } else {
      console.log("loginRes", loginRes);
      notifyError(loginRes?.message || "Something went wrong");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen loginPage">
      <div className="bg-white shadow-lg py-4 px-8 w-full border-[1px] xs:w-[70%] sm:w-[50%] md:w-[50%] lg:w-[40%] xl:w-[35%] m-auto rounded-lg">
        <div className="flex justify-center items-center">
          <Image
            src="/images/flooredathomeCrop.png"
            className=" w-[150px] object-cover"
          />
        </div>
        <LoginForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmitLoginForm}
          register={register}
          errors={errors}
          watch={watch}
        />
      </div>
    </div>
  );
};

export default Login;
