import { Box, Link } from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import MuiTextField from "../UI/MuiTextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";

const ForgotPasswordForm = ({ handleSubmit, onSubmit, register, errors }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate("/login");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className="flex flex-col space-y-5 mt-8">
        <MuiTextField
          id="forgotemail"
          name="forgotemail"
          label="Email"
          type="email"
          variant="outlined"
          register={register}
          error={errors?.forgotemail || ""}
        />
      </Box>
      <Link
        sx={{ marginY: "12px" }}
        component="button"
        variant="body2"
        type="button"
        onClick={handleBackButtonClick}
        className="flex my-3"
      >
        <ArrowBackIcon style={{ fontSize: "18px", marginRight: "4px" }} />
        Back
      </Link>
      <Box className="flex justify-center my-5 mb-10 ">
        <Button
          type="submit"
          className="py-2 px-5 cursor-pointer bg-black text-white rounded-md text-center"
        >
          Continue
        </Button>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
