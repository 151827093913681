import React from "react";
import CustomerDetails from "./CustomerDetails";
import MatterportView from "./MatterportView";

const BasicInfo = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-7  gap-2 mx-[30px] h-full ">
      <div className="bg-white  col-span-1 md:col-span-2 ">
        <CustomerDetails />
      </div>
      <div className="bg-white col-span-1 md:col-span-3 flex xs:h-[300px] sm:h-[500px]  justify-center items-center p-2">
        <img
          src="/images/roomimage.jpeg"
          alt="customer image"
          className="p-2  object-contain object-center"
          style={{ objectFit: "contain", objectPosition: "center" }}
        />
        {/* <MatterportView /> */}
      </div>
      <div className="bg-white col-span-1 md:col-span-2">FAQ</div>
    </div>
  );
};

export default BasicInfo;
