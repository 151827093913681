import React, { useEffect, useRef, useState } from "react";
import { setupSdk } from "@matterport/sdk";
import "@matterport/webcomponent";

const MatterportView = () => {
  const [sdk, setSdk] = useState();
  const [horizontal, setHorizontal] = useState(45);
  const [vertical, setVertical] = useState(15);
  const container = useRef(null);
  let started = false;

  useEffect(() => {
    if (!started && container.current) {
      started = true;
      setupSdk("77p5tdqrdhhztmsa8wbw3e4zd", {
        container: container.current,
        space: "ymFPJif9anq",
        iframeQueryParams: { qs: 10 }
      }).then(setSdk);
    }
    console.log("container.current-", container.current);
  }, []);
  return (
    <div
      onClick={(e) => console.log(e, "e--")}
      className="container !h-full"
      style={{ height: "100%" }}
      ref={container}
    ></div>
  );
};

export default MatterportView;
