import axios from "axios";
import { getAuth, setAuth } from "./identity.service";
let AxiosCreator;
const ApiUrl = process.env.REACT_APP_API_URL;

export const nodeApiUrl = ApiUrl;

let auth = getAuth();

AxiosCreator = axios.create({
  baseURL: nodeApiUrl,
  headers: {
    Authorization: auth?.access_token || ""
  }
});

AxiosCreator.interceptors.request.use((config) => {
  const configHeaders = config.headers;
  configHeaders.Authorization = `Bearer ${auth?.access_token}`;
  return config;
});

// AxiosCreator.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   (err) => {
//     if (err?.response?.status === 401) {
//       console.log("401 err : ", err);
//       // window.location.href = "/login";
//     }

//     throw err?.response;
//   }
// );

function createAxiosResponseInterceptor() {
  console.log(auth, "auth==");
  const interceptor = AxiosCreator.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log(error?.response?.data, "error==");
      // Reject promise if usual error
      if (
        error.response.status === 401 &&
        error?.response?.data?.message === "Token expired"
      ) {
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response.
         *
         * Must be re-attached later on or the token refresh will only happen once
         */
        AxiosCreator.interceptors.response.eject(interceptor);
        let currentAuth = getAuth();
        return AxiosCreator.post("/auth/refresh-tokens", {
          refreshToken: currentAuth?.refresh_token
        })
          .then((response) => {
            console.log("response-", response);
            //   localStorage.setItem("accessToken", response);
            const oldAuth = { ...currentAuth };
            oldAuth.access_token = response.data?.entity?.accessToken;
            oldAuth.refresh_token = response.data?.entity?.refreshToken;

            setAuth(oldAuth);
            auth = oldAuth;
            console.log(oldAuth, currentAuth, "Auth---");
            error.response.config.headers["Authorization"] =
              "Bearer " + response.data?.entity?.accessToken;
            // Retry the initial call, but with the updated token in the headers.
            // Resolves the promise if successful
            return axios(error.response.config);
          })
          .catch((error2) => {
            console.log(error2, "error2");
            // Retry failed, clean up and reject the promise
            //   destroyToken();
            //   logout();
            //   localStorage.removeItem("accessToken");
            //   localStorage.removeItem("AUTH");
            //   this.router.push("/login");
            return Promise.reject(error2);
          })
          .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running the method
      } else {
        return Promise.reject(error);
      }
    }
  );
}

createAxiosResponseInterceptor();

export default AxiosCreator;
