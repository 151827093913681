import * as HttpService from "./http.service";
import {
  ADD_CUSTOMER_FV_DATA,
  CHANGE_PASSWORD_WITHLOGIN,
  CHANGE_PASSWORD_WITHOUTLOGIN,
  CREATE_PASSWORD_WITHOUTLOGIN,
  DOWNLOAD_IMAGES_PDF,
  DOWNLOAD_PDF,
  FORGOT_PASSWORD,
  GET_ALL_DATA,
  GET_CUSTOMER_BY_ID,
  GET_FLOOR_PLAN_BY_ZC_PO_ID,
  LOGOUT,
  PUSH_FVDATA_TO_ZOHO,
  RESET_PASSWORD_WITHOUTLOGIN,
  SYSTEM_ADMIN_LOGIN_URL,
  UPDATE_CUSTOMER_FV_DATA,
  UPLOAD_IMAGE,
} from "./url.service";

export const getAllData = (token, size, page, search) => {
  return HttpService.getWithAuth(GET_ALL_DATA(size, page, search), token);
};

export const getCustomerById = (customerId, token) => {
  return HttpService.getWithAuth(GET_CUSTOMER_BY_ID(customerId), token);
};

export const addCustomerFVData = (fv_data) => {
  return HttpService.postWithAuth(ADD_CUSTOMER_FV_DATA(), fv_data);
};

export const updateCustomerFVData = (customerId, fv_data) => {
  return HttpService.putWithAuth(UPDATE_CUSTOMER_FV_DATA(customerId), fv_data);
};

export const uploadImage = (img) => {
  return HttpService.postWithAuthImg(UPLOAD_IMAGE(), img);
};

//Fetch floor plan from zc_po_id from zoho
export const getFloorPlanZcPoId = (zc_po_id, token) => {
  return HttpService.getWithAuth(GET_FLOOR_PLAN_BY_ZC_PO_ID(zc_po_id), token);
};

// Push fvdata to zoho
export const pushFVDatatoZoho = (fv_data) => {
  return HttpService.zohoPostWithAuth(PUSH_FVDATA_TO_ZOHO(), fv_data);
};

// new Apis
export const createPasswordWithoutLogin = (data) => {
  return HttpService.postWithOutAuth(
    CREATE_PASSWORD_WITHOUTLOGIN(data?.token),
    { password: data?.password }
  );
};
export const resetPasswordWithoutLogin = (data) => {
  return HttpService.patchWithOutAuth(
    RESET_PASSWORD_WITHOUTLOGIN(data?.token),
    {
      password: data?.password,
    }
  );
};
export const changePasswordWithLogin = (data) => {
  return HttpService.patchWithAuth(CHANGE_PASSWORD_WITHLOGIN(), data);
};

export const adminLogin = (data) => {
  return HttpService.postWithOutAuth(SYSTEM_ADMIN_LOGIN_URL(), data);
};
export const forgotPassword = (data) => {
  return HttpService.postWithOutAuth(FORGOT_PASSWORD(), data);
};
export const logout = (data) => {
  return HttpService.postWithAuth(LOGOUT(), data);
};
