import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import Header from "../../UI/Header";

import { getAuth } from "../../../services/identity.service";
import BasicInfo from "./BasicInfo";
import QuestionPanel from "./QuestionPanel";

const HomePage = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const auth = getAuth();
  useEffect(() => {
    let user = localStorage?.getItem("AUTH");
    setUserInfo(JSON?.parse(user));
  }, []);
  //   console.log(JSON?.parse(userInfo), "userInfo");
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div className="flex flex-col bg-[#dde6e9] overflowY-auto max-w-[1350px] m-auto">
      <div className=" flex justify-center items-center ">
        <Header />
      </div>
      <div className=" ">
        <BasicInfo />
      </div>
      <div className="">
        <QuestionPanel />
      </div>
    </div>
  );
};

export default HomePage;
