import React, { useEffect } from "react";
import * as yup from "yup";
import { useReactHookForm } from "../../hooks/useReactHookForm";
import { adminLogin, forgotPassword } from "../../services/customers.service";
import { getAuth, setAuth } from "../../services/identity.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import MuiSnackbar from "../UI/MuiSnackbar";
import LoginForm from "./LoginForm";
import Image from "../UI/Image";
import MuiTextField from "../UI/MuiTextField";
import { Box, Link } from "@mui/material";
import Button from "../UI/Button";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { notifyError, notifySuccess } from "../UI/Toaster";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const redirectUri = searchParams.get("redirectUri");

  const navigate = useNavigate();
  const auth = getAuth();

  const handleBackButtonClick = () => {
    // Navigate to the forgot password route
    navigate("/login");
  };

  useEffect(() => {
    if (!!auth?.token && !origin && !redirectUri) {
      navigate("/");
    }
  }, [auth, navigate]);

  const EventSchema = yup.object().shape({
    forgotemail: yup
      .string()
      .email("Email is invalid ")
      .nullable()
      .required("Email is required!"),
  });

  const { handleSubmit, register, errors, watch } = useReactHookForm({
    validationSchema: EventSchema,
    defaultValues: {},
    mode: "onChange",
  });

  const onSubmitForgotForm = async (data) => {
    console.log(data, "data");

    let udpatedData = { email: data?.forgotemail };

    let ApiRes = await forgotPassword(udpatedData);
    if (ApiRes?.data?.status) {
      notifySuccess(ApiRes?.data?.message || "Reset Link Sent To Your Email");

      navigate("/login");
    } else {
      notifyError(ApiRes?.message || "Something went wrong");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen loginPage">
      <div className="bg-white shadow-lg py-8 px-8 w-full border-[1px] md:w-[50%] lg:w-[40%] xl:w-[35%] m-auto rounded-lg">
        {/* <div className="flex justify-center items-center">
          <Image
            src="/images/fv_logo.svg"
            className=" w-[150px] object-cover"
          />
        </div> */}
        <div className="flex flex-col ">
          <label className="text-[30px] font-semibold mt-4">
            Forgot Password ?
          </label>
          <label className="text-[20px] font-semibold mt-4">
            Enter your email address
          </label>
        </div>
        <ForgotPasswordForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmitForgotForm}
          register={register}
          errors={errors}
          watch={watch}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
