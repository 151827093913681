import { Box, Link } from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import MuiTextField from "../UI/MuiTextField";
import { getAuth } from "../../services/identity.service";

const ChangePasswordForm = ({ handleSubmit, onSubmit, register, errors }) => {
  const auth = getAuth();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className="flex flex-col space-y-5 mt-8">
        {auth && (
          <MuiTextField
            id="currentpassword"
            name="currentpassword"
            label="Current Password"
            type="password"
            variant="outlined"
            register={register}
          />
        )}

        <MuiTextField
          id="newpassword"
          name="newpassword"
          label="New Password"
          type="password"
          variant="outlined"
          register={register}
          error={errors?.newpassword || ""}
        />
        <MuiTextField
          id="confirmpassword"
          name="confirmpassword"
          Þþ
          label="Confirm New Password"
          type="password"
          variant="outlined"
          register={register}
          error={errors?.confirmpassword || ""}
        />
      </Box>

      <Box className="flex justify-center my-5 mb-10 ">
        <Button
          type="submit"
          className="py-2 px-5 cursor-pointer bg-black text-white rounded-md text-center"
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
