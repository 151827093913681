import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getAuth } from "../services/identity.service";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment/moment";

export const AuthRequired = ({ children }) => {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (!auth?.access_token) {
      navigate("/login");
    } else {
      const decoded = jwtDecode(auth?.access_token);
      const currentTime = moment().unix();
      console.log("decoded", decoded, currentTime);
      // if (currentTime > decoded?.exp) {
      //   console.log(auth, "authRequired--");
      //   localStorage.removeItem("AUTH");
      //   navigate("/login");
      // }
    }
  }, [auth, navigate]);

  return auth?.access_token ? children : <Navigate to="/login" />;
};
