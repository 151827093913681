import axios from "axios";
import { getAuth } from "./identity.service";
import AxiosCreator from "./AxiosCreator";

export const postWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    AxiosCreator.post(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response?.data });
        }
      })
      .catch((ex) => {
        console.log(ex, "ex");
        resolve({ status: false, message: ex.response?.data?.message });
      });
  });
};

export const postWithAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${auth.access_token}`
    };
    AxiosCreator.post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.response.data?.message });
      });
  });
};

export const zohoPostWithAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${auth.access_token}`
    };
    AxiosCreator.post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({
          status: false,
          data: ex.response.data
        });
      });
  });
};

export const postWithAuthImg = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${auth.access_token}`
    };
    AxiosCreator.post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};

export const getWithOutAuth = (url) => {
  return new Promise((resolve, _reject) => {
    AxiosCreator.get(url)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const getWithAuthAndBody = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${auth.access_token}`
    };
    AxiosCreator.get(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};

export const getWithAuth = (url, token = "") => {
  const headers = {
    "content-type": "application/json",
    Authorization: token
  };
  return new Promise((resolve, _reject) => {
    AxiosCreator.get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const getWithAuthForPDF = (url, token = "") => {
  const headers = {
    "content-type": "application/json",
    Authorization: token
  };
  return new Promise((resolve, _reject) => {
    AxiosCreator.get(url, { headers }, { responseType: "blob" })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const getWithAuthWithToken = (url) => {
  const auth = getAuth();

  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${auth.access_token}`
  };
  return new Promise((resolve, _reject) => {
    AxiosCreator.get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, error: ex.message });
      });
  });
};

export const deleteWithAuth = (url, entity) => {
  const auth = getAuth();

  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${auth.access_token}`
  };

  return new Promise((resolve, _reject) => {
    AxiosCreator.delete(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};

export const putWithAuth = (url, data) => {
  const auth = getAuth();
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${auth.access_token}`
  };
  return new Promise((resolve, _reject) => {
    AxiosCreator.put(url, data, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};
export const putWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    AxiosCreator.put(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};
export const patchWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    AxiosCreator.patch(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, message: ex.message });
      });
  });
};

export const patchWithAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${auth.access_token}`
    };
    AxiosCreator.patch(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve({ status: true, data: response?.data });
        }
      })
      .catch((ex) => {
        resolve({ status: false, data: ex.response?.data });
      });
  });
};
