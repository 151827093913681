import React, { useEffect, useState } from "react";
import { TbHomeExclamation } from "react-icons/tb";
import Button from "../../UI/Button";

const CustomerDetails = () => {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    let user = localStorage?.getItem("AUTH");
    setUserInfo(JSON?.parse(user));
  }, []);
  console.log("userInfo", userInfo);
  return (
    <div className="flex flex-col p-4 text-[13px]">
      <span className="flex justify-start text-left text-[13px]">Hi,</span>
      <span className="flex justify-center text-[15px] text-[#4ec9ff]">
        {`${userInfo?.first_name} 
        ${userInfo?.last_name}`}
      </span>
      <div className="my-1.5">
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            Email Address
          </div>
          <div>{userInfo?.email}</div>
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            Other Phone
          </div>
          <div>{userInfo?.other_phone || ""}</div>
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            Mobile
          </div>
          <div>{userInfo?.mobile_phone}</div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-[gray]" />
      <div className="flex flex-col md:flex-row text-start my-1.5">
        <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
          Apt Date&Time
        </div>
        <div>04-Feb 2022 09:00:00 AM</div>
      </div>
      <div className="w-full h-[1px] bg-[gray]" />
      <div className="my-1.5">
        <div className="text-[15px] text-[#082d6d] text-left mb-2">
          Address Info
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            Street
          </div>
          <div>Gourley Ave</div>
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            Unit
          </div>
          <div>82</div>
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            City
          </div>
          <div>Clifton</div>
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            State
          </div>
          <div>NJ</div>
        </div>
        <div className="flex flex-col md:flex-row text-start">
          <div className="w-full md:w-[30%] mr-3 text-[gray] mb-1 md:mb-0">
            Zip Code
          </div>
          <div>07013</div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-[gray]" />
      <div className="flex justify-between items-center text-start my-1.5">
        <div className="flex">
          <TbHomeExclamation style={{ fontSize: "24px" }} />
          <TbHomeExclamation style={{ fontSize: "24px" }} />
        </div>
        <div className="w-[2px] h-[80%] bg-[gray]" />
        <div>
          <Button className="py-2 px-5 cursor-pointer bg-green-600 text-white rounded-md text-center">
            Pay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
